import * as React from 'react'
import { useState, useEffect } from 'react'
import { IconChevronRight } from '../Icons'
import { Link } from 'gatsby'

const SubCategoryMenu = ({ subcategories, className = '', alignment = 'left-0' }) => {
  const [subCatToggle, setSubCatToggle] = useState({ hover: false, clicked: false })
  const [showSubCat, setShowSubCat] = useState(false)
  useEffect(() => {
    let timeout
    if (subCatToggle.hover || subCatToggle.clicked) {
      setShowSubCat(true)
      return
    }
    timeout = setTimeout(() => {
      setShowSubCat(false)
    }, 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [subCatToggle, setShowSubCat])

  return (
    <div
      className={'relative mx-2 ' + className}
      onMouseEnter={() => setSubCatToggle(prev => ({ ...prev, hover: true }))}
      onMouseLeave={() => setSubCatToggle(prev => ({ ...prev, hover: false }))}
      onClick={() => setSubCatToggle(prev => ({ hover: false, clicked: !prev.clicked }))}
    >
      <button className="w-7 h-7 aspect-square group rounded-full border-2 border-gray-400 text-gray-400 flex justify-center items-center  hover:text-black hover:bg-gray-100 hover:border-black duration-200">
        <IconChevronRight
          className={
            (showSubCat ? ' rotate-90 translate-y-[.05rem]' : ' rotate-0 translate-x-[.05rem]') +
            ' h-3 stroke-2 group-hover:rotate-90 group-hover:translate-y-[.05rem] group-hover:-translate-x-[0rem] duration-200  '
          }
        />
      </button>
      <div
        className={
          (showSubCat ? alignment + ' lg:block' : ' -left-[120vw] lg:hidden') +
          ' z-[110] fixed overflow-y-auto top-0 duration-500 w-full h-full lg:w-auto lg:h-auto lg:absolute lg:top-8 lg:p-5 lg:rounded-xl lg:border lg:border-gray-300 bg-white shadow-lg text-black text-lg font-normal'
        }
      >
        {/* TODO: Greg the background is scrollable when this is on. */}
        <div className="fade-in-now lg:px-0 text-sm uppercase flex flex-col overflow-auto p-1 h-full w-full text-gray-600 lg:max-w-max relative">
          <button
            onClick={() => setShowSubCat(!showSubCat)}
            className="lg:hidden w-full flex justify-start bg-red-700 max-w-max px-4 py-2 text-white"
          >
            CLOSE
          </button>
          {subcategories.length > 0 &&
            subcategories.map(subcategory => (
              <Link
                to={subcategory.url}
                key={subcategory.url}
                className="border-gray-300 z-20 flex-shrink-0 pl-10 py-3 lg:pl-0 whitespace-nowrap text-gray-600 hover:text-red-700 lg:py-2 w-full duration-150 lg:flex-shrink ease-in text-ellipsis overflow-hidden"
              >
                {subcategory.name}
              </Link>
            ))}
        </div>
      </div>
    </div>
  )
}
export default SubCategoryMenu
